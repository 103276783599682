<template>
  <div class="home-section confian-section">
    <div class="confian-image">
        <img src="../assets/confian.svg">
    </div>
    <div class="confian-info">
        <p>
            En <strong>PASeguros</strong> vas a encontrar la más amplia red de productores de seguros matriculados y certificados por las más prestigiosas aseguradoras de nuestro país.<br><br>
            Según tu ubicación al momento de cotizar, elegí productor, contratá y quedate tranquilo, del resto nos ocupamos nosotros.<br><br>
            <!--a href="#">!Consultá nuestro listado de productores disponibles ahora!</a-->

        </p>
    </div>
    
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.confian-section {
    width: 90%;
    margin: 40px 5%;
    overflow: hidden;
    .confian-info {
        float: right;
        width: 60%;
        text-align: left;

        strong {
            font-family: Montserrat;    
            font-weight: 700;
        }
        p {
            font-family: Montserrat;
            font-size: 1.1rem;
            color: #FFFFFF;
            line-height: 2;
            color: #13254A;
            letter-spacing: -0.15px;
        }
    }
    .confian-image {
        float: left;
        width: 40%;
        margin-top: 50px;
        img {
            width: 70%;
            display: block;
        }
    }
    @media all and (max-width: 480px){
        .confian-info {
            float: none;
            width: 70%;
            text-align: center;
            margin: 0 auto;

        }
        .confian-image {
            
            width: 100%;
            margin-bottom:20px;
            img {
                width: 70%;
                margin: 0 auto;
                display: block;
            }
        }
    }
}
</style>
