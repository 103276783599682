import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import store from "@/store/index";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [  
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "PASeguros | Home",
      metaTags: [
        {
          name: "description",
          content: ""
        },
        {
          property: "og:description",
          content: ""
        }
      ]
    },
    beforeEnter(routeTo, routeFrom, next) {
      console.log(routeTo);
      store
        .dispatch("productores/fetchProductores", routeTo.params.id)
        .then((productores) => {
          console.log(productores);
          next();
        })
        .catch((error) => {
          console.log(error);
          //if (error.response && error.response.status == 404) {
          //  next({ name: "404", params: { resource: "event" } });
          //} else {
          //  next({ name: "network-issue" });
          // }
        });
    }
  },
  {
    path: "/autos",
    name: "Autos",
    meta: {
      title: "PASeguros | Autos",
      metaTags: [
        {
          name: "description",
          content: ""
        },
        {
          property: "og:description",
          content: ""
        }
      ]
    },
    component: () => import("../views/Autos.vue"),
    beforeEnter(routeTo, routeFrom, next) {
      console.log(routeTo);
      store
        .dispatch("productores/fetchProductores", routeTo.params.id)
        .then((productores) => {
          console.log(productores);
          next();
        })
        .catch((error) => {
          console.log(error);
          //if (error.response && error.response.status == 404) {
          //  next({ name: "404", params: { resource: "event" } });
          //} else {
          //  next({ name: "network-issue" });
          // }
        });
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((routeTo, routeForm, next) => {
  
  NProgress.start();
  
  const nearestWithTitle = routeTo.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = routeTo.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  //const previousNearestWithMeta = routeForm.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;
  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));
  
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;