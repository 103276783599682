import ProductoresServices from "@/services/ProductoresServices.js";

export const namespaced = true;

export const state = {
  productores: []  
};
export const mutations = {
  GET_PRODUCTORES(state, productores) {
    state.productores = productores;
  }
};
export const actions = {
  fetchProductores({ commit, dispatch, state }) {
      console.log("here");
    return ProductoresServices.getProductores(state.perPage)
      .then((response) => {
        //commit("SET_TOTAL_SCULPTURES", response.headers["x-total-count"]);
        commit("GET_PRODUCTORES", response.data);
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: "Tuvimos un problema al traer eventos: " + error.message,
        };
        dispatch("notification/add", notification, { root: true });
      });
  }
};
export const getters = {
  /*getSculptureById: (state) => (id) => {
    return state.sculptures.find((sculpture) => sculpture.id === id);
  },*/
};
