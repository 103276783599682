import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "nprogress/nprogress.css";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import Vuelidate from "vuelidate";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(Vuelidate);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBDuJ4FYyx5EI-C6mPdhOf5sa-PDhaAFU4",
    libraries: "places" // necessary for places input
  }
});

const requireComponent = require.context(
  "./components",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );

  Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
