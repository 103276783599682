<template>
  <div class="home">    
    <header>
      <div class="logo"><img alt="Vue logo" src="../assets/logo_paseguros_header.svg" /></div>
      <NavBar />
    </header>
    <Intro />
    <Confian />
    <Sobre />
    <ComoFunciona />
    <NuestrosProductos />
    <Productores />
    <Contactanos />
    <Footer />
    <!--Home msg="Welcome to Your Vue.js App" /-->
  </div>
</template>

<script>
// @ is an alias to /src
import Intro from "@/components/Intro.vue";
import Confian from "@/components/Confian.vue";
import Sobre from "@/components/Sobre.vue";
import ComoFunciona from "@/components/ComoFunciona.vue";
import NuestrosProductos from "@/components/NuestrosProductos.vue";
import Productores from "@/components/Map.vue";
import Footer from "@/components/Footer.vue";
import NavBar from "@/components/NavBar.vue";
import Contactanos from '../components/Contactanos.vue';

export default {
  /*name: "Home",*/
  components: {
    Intro,
    Confian,
    Sobre,
    NavBar,
    ComoFunciona,
    NuestrosProductos,
    Productores,
    Footer,
    Contactanos
  }
};
</script>

<style lang='scss' scoped>


</style>