import axios from "axios";
//import Nprogress from 'nprogress'

const apiClient = axios.create({
  baseURL: "https://cotizaciones.procloud.com.ar/infoautos",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  timeout: 1000000
});

/*apiClient.interceptors.request.use(config => {
  Nprogress.start()
  return config
})

apiClient.interceptors.response.use(response => {
  Nprogress.done()
  return response
})*/

export default {
  getBrands() {
    return apiClient.get("/get/brands");
  },
  getModels(brandId) {
    return apiClient.get("/get/models?brandId="+brandId);
  },
  getYears(brandId) {
    return apiClient.get("/get/models?brandId="+brandId);
  },
  getVersions(brandId, modelId) {
    return apiClient.get("/get/versions?brandId="+brandId+"&modelId="+modelId);
  }
};
