<template>
  <footer>
      <div class="logo-footer"><img src="../assets/logo_paseguros.svg"></div>
      <p>
          Producto desarrollado por Procloud SRL (CUIT: 33-71601385-1) en colaboración con Romasanta y Asociados SA<br>
          (CUIT: 30709042412 - Matrícula SSN N° 900)<br>
          Superintendencia de Seguros de la Nación 0800-666-8400 / 4338-4000 | <strong>www.ssn.gob.ar</strong>
      </p>
  </footer>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>


footer {
    padding: 40px;
    text-align: center;
}
</style>
