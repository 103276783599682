import Vue from "vue";
import Vuex from "vuex";
import * as productores from "@/store/modules/productores.js";
import * as notification from "@/store/modules/notification.js";
import * as infoauto from "@/store/modules/infoauto.js";
import * as procloud from "@/store/modules/procloud.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    productores,
    notification,
    infoauto,
    procloud
  },
  state: {},
});
