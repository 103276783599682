<template>
  <div class="home-section como-funciona-section" id="como-funciona">
    <div class="como-funciona-info">
        <h2>¿Cómo Funciona?</h2>
        <p>
            En <strong>PASeguros</strong> vas a encontrar la más amplia red de productores de seguros matriculados.
        </p>
    </div>
    <div class="como-funciona-content">
        <div class="col-4">
            <div class="como-funciona-item-number">
                1
                <div class="line"></div>
            </div>
            <div class="como-funciona-item-image">
                <img src="../assets/monitor-loading-progress.svg" alt="">
            </div>
            <h3>Mensaje</h3>
            <h4>Tu productor se contactará</h4>
            <p>Si tu productor te mandó el mail, contratá! ya sabemos quien es tu productor! Si nunca recibiste su mensaje, un asesor te pedirá sus datos antes de emitir su póliza.</p>            
        </div>
        <div class="col-4">
            <div class="como-funciona-item-number">
                2
                <div class="line"></div>
            </div>
            <div class="como-funciona-item-image">
                <img src="../assets/customer-service-woman.svg" alt="">
            </div>
            <h3>Obtené un productor</h3>
            <h4>¿No tenés?, ¡Elegilo!</h4>
            <p>Si no tenés productor de seguros y estás buscando uno, consultá nuestro mapa y una vez que elijas, ya podés contratar tu póliza!</p>            
        </div>
        <div class="col-4">
            <div class="como-funciona-item-number">
                3
                <div class="line"></div>
            </div>
            <div class="como-funciona-item-image">
                <img src="../assets/postman-receive-letter.svg" alt="">
            </div>
            <h3>¡Disfruta!</h3>
            <h4>Nos ocupamos de todo</h4>
            <p>Una vez emitida la póliza, el productor te la enviará para que ya te queden sus datos y puedas comunicarte con él/ella, cada vez que lo necesites.</p>            
        </div>
    </div>
    
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>

.como-funciona-item-number {
    position: absolute;
    left: 15px;
    top: 20px;
    width: 50px;
    height: 50px;
    padding: 0; 
    margin: 0;
    font-size: 1.4rem;
    font-family: Montserrat;
    .line {
        width: 62px;
        height: 47px;
        border-bottom: 1px solid #000000;
        -webkit-transform:
            translateY(20px)
            translateX(5px)
            rotate(-46deg);
        position: absolute;
        top: -33px;
        left: -13px;
    }
}
.como-funciona-section {
    width: 90%;
    margin: 40px 5%;
    overflow: hidden;
    .como-funciona-info {
        width: 100%;
        text-align: center;
        h2 {
            font-size: 1.8rem;
        }
        strong {
            font-family: Montserrat;    
            font-weight: 700;
        }
        p {
            font-family: Montserrat;
            font-size: 1.1rem;
            color: #FFFFFF;
            line-height: 2;
            color: #13254A;
            letter-spacing: -0.15px;
        }
    }
    .como-funciona-content {
        width: 90%;
        margin: 0 auto;
        .col-4 {
            width: 33.33%;
            float: left;
            background: #FFFFFF;
            border: 1px solid #D1D1D1;
            position: relative;
            padding: 20px;
            box-sizing: border-box;
            min-height: 610px;
            font-family: Montserrat;
            .como-funciona-item-image {
                min-height: 195px;
            }
            h3 {
                font-size: 1.4rem;
            }
            h4 {
                font-size: 1.2rem;
                padding-bottom: 40px;
                border-bottom: 0.5px solid #999;
                margin-bottom: 40px;
            }
            p {
                font-size: 1rem;
                color: #000000;
                text-align: center;
                line-height: 2.2rem;
                padding: 0 20px;
            }
        }
    }
    @media all and (max-width: 768px){
        .como-funciona-content {
            .col-4 {
                width: 100%;
                margin-bottom: 15px;
            }
        }
    }
}

</style>
