  <template>
  <div class="home-section contactanos-section" id='contactanos'>
    <div class="contactanos-info">
        <h2>Contactanos</h2>
        <p>
            Podes contactarnos a través de nuestras redes sociales o nuestro teléfono<br>
            <strong>011-5352-5083</strong>
        </p>
    </div>     
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>

.contactanos-section {
    width: 100%;
    margin: 0px 0%;
    padding: 30px 0;
    overflow: hidden;
    background: #E9FEF7;

    .contactanos-info {
        width: 100%;
        text-align: center;
        h2 {
            font-size: 1.8rem;
        }
        strong {
            font-family: Montserrat;    
            font-weight: 700;
        }
        p {
            font-family: Montserrat;
            font-size: 1.1rem;
            color: #FFFFFF;
            line-height: 2;
            color: #13254A;
            letter-spacing: -0.15px;
        }
    }
}

</style>
