<template>
  <div class="home-section nuestros-productos-section" id="productos">
    <div class="nuestros-productos-info">
        <h2>Nuestros productos</h2>
        <p>
            A continuación podés ver todos nuestros productos y obtener una cotización por el que quieras.
        </p>
    </div>
    <div class="nuestros-productos-content">
        <div class="col-4">            
            <div class="nuestros-productos-item-image">
                <a href="https://producto.paseguros.com.ar/bicicleta" target="_blank"><img src="../assets/logo_paseguros_bici.png" alt=""></a>
            </div>            
        </div>
        <div class="col-4">            
            <div class="nuestros-productos-item-image">
                <a href="https://producto.paseguros.com.ar/alquileres" target="_blank"><img src="../assets/logo-alquileres-dark.svg" alt=""></a>
            </div>            
        </div>
        <div class="col-4">            
            <div class="nuestros-productos-item-image">
                <a href="https://mascotas.paseguros.com.ar/" target="_blank"><img src="../assets/logo-mascotas-dark.svg" alt=""></a>
            </div>            
        </div>
    </div>
    
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>


.nuestros-productos-section {
    width: 100%;
    margin: 20px 0% 0;
    padding: 30px 0;
    overflow: hidden;
    background: #FAFAFA;

    min-height: 400px;

    .nuestros-productos-info {
        width: 100%;
        text-align: center;
        h2 {
            font-size: 1.8rem;
        }
        strong {
            font-family: Montserrat;    
            font-weight: 700;
        }
        p {
            font-family: Montserrat;
            font-size: 1.1rem;
            color: #FFFFFF;
            line-height: 2;
            color: #13254A;
            letter-spacing: -0.15px;
        }
    }
    
    .nuestros-productos-content {
        width: 80%;
        margin: 100px auto;
        .col-4 {
            width: 33.33%;
            float: left;
            position: relative;
            padding: 20px;
            box-sizing: border-box;
            font-family: Montserrat;
            .nuestros-productos-item-image {
                
            }
            h3 {
                font-size: 1.4rem;
            }
            h4 {
                font-size: 1.2rem;
                padding-bottom: 40px;
                border-bottom: 0.5px solid #999;
                margin-bottom: 40px;
            }
            p {
                font-size: 1rem;
                color: #000000;
                text-align: center;
                line-height: 2.2rem;
                padding: 0 20px;
            }
        }
    }
     @media all and (max-width: 768px){
         .nuestros-productos-info {
            p  {
                padding: 0 15px;
            }
            
         }
        .nuestros-productos-content {
            .col-4 {
                width: 100%;
                float: left;
                margin-bottom: 20px;
            }
        }
    }
}

</style>
