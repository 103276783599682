  <template>
  <div class="home-section productores-section" id='productores'>
    <div class="productores-info">
        <h2>Mapa Interactivo</h2>
        <p>
            En nuestro mapa interactivo podrás buscar las oficinas más cercanas a tu ubicación, los horarios de atención y hacer tu elección, o confirmar si tu productor ya está asociado.            
        </p>
    </div>

    <!--div  v-for="productor in productores.productores"
      :key="productor.id"
      :productor="productor">
      {{ productor.fantasy_name }}
      {{ productor.location.address }}</div>-->
        <div>
        <!--h2>Search and add a pin</h2>
        <label>
            <gmap-autocomplete
            @place_changed="setPlace">
            </gmap-autocomplete>
            <button @click="addMarker">Add</button>
        </label-->
        <gmap-map :center="center" :zoom="10" style="width:100%;  height: 400px;">
            <gmap-marker :key="index" v-for="(m, index) in markers" :position="m.position" :icon="{ url: m.icon}" @click="toggleInfoWindow(m,index)"></gmap-marker>

            <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen=false">
            <div v-html="infoContent"></div>
          </gmap-info-window>
        </gmap-map>    
        </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/index";
//import gsap from "gsap";

function getProductores(routeTo, next) {
  const currentPage = parseInt(routeTo.query.page) || 1;
  store
    .dispatch("productores/fetchProductores", {
      page: currentPage
    })
    .then(() => {
      routeTo.params.page = currentPage;
      next();
    });
}

export default {
  props: {
  },
  components: {    
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    getProductores(routeTo, next);
  },
  beforeRouteUpdate(routeTo, routeFrom, next) {
    getProductores(routeTo, next);
  },
  computed: {   
      ...mapState(["productores"])
  },
  name: "GoogleMap",
  data() {
      
        
    return {
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      center: { lat: -34.60085926302077, lng: -58.27508760231044 },
      markers: [],
      places: [],
      currentPlace: null,
      icons: [],
      infoContent: '',
        infoWindowPos: {
          lat: 0,
          lng: 0
        },
        infoWinOpen: false,
        currentMidx: null,
        //optional: offset infowindow so it visually sits nicely on top of our marker
        infoOptions: {
          pixelOffset: {
            width: 0,
            height: -35
          }
        }
    };
  },
  mounted() {
    this.geolocate();
    console.log();

   const iconBase = "https://developers.google.com/maps/documentation/javascript/examples/full/images/";

    this.icons = {
      parking: {
        icon: iconBase + "parking_lot_maps.png",
      },
      library: {
        icon: iconBase + "library_maps.png",
      },
      info: {
        icon: iconBase + "info-i_maps.png",
      },
      productor: {
        icon: 'http://paseguros.com.ar/img/marker-productores-50px.png'
      }
    };
    /*const marker1 = {
          lat: -34.60085926302077,
          lng: -58.37629425813681
        };
    const marker2 = {
        lat: -34.71691056018943,
        lng:  -58.27508760231044
    };*/
    for (let index = 0; index < this.productores.productores.length; index++) {
      const element = this.productores.productores[index];
      //console.log(element.location.geolocation.lat);
      if (element.location.geolocation.lat != null) {
        console.log(this.icons['library'].icon);
      //console.log(this.productores.productores[index].location.geolocation );
        this.markers.push({ 
          position: {lat: Number(element.location.geolocation.lat), lng:  Number(element.location.geolocation.lng)},
          icon: this.icons['productor'].icon,
          name: element.fantasy_name,
          description: element.phone+ "<br>"+element.mail,
          logo: element.logo,
          active_codes: element.active_codes
          });
      }
      
    }
    
    //this.markers.push({ position: marker2 });
    /*gsap.from(".sculpture-item", {
      duration: 0.5,
      opacity: 0,
      scale: 0,
      y: 200,
      ease: "power1",
      stagger: 0.1
    });*/
  },
  methods: {
    toggleInfoWindow: function (marker, idx) {
        this.infoWindowPos = marker.position;
        this.infoContent = this.getInfoWindowContent(marker);
        

        //check if its the same marker that was selected if yes toggle
        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen;
        }
        //if different marker set infowindow to open and reset current marker index
        else {
          this.infoWinOpen = true;
          this.currentMidx = idx;
        }
      },
      getInfoWindowContent: function (marker) {
          //var logo = 'https://bulma.io/images/placeholders/96x96.png';
          var logo = 'http://paseguros.com.ar/img/logo_paseguros.02221369.svg';
          if (marker.logo != null) {
            logo = marker.logo;
          }


          var companies = '';
          if (marker.active_codes != null) {
              companies = 'Principales compañías<br>';
            for (let index = 0; index < marker.active_codes.length; index++) {
                const element = marker.active_codes[index];              
                companies += '<img src="'+element.company.url_logo+'" height="18px" style="margin: 0 1px;">';
            }
          }
              return (`<div class="card">
                        <div class="card-image">
                          <figure class="image is-4by3">
                            <img src="${logo}" alt="Placeholder image" width="100px">
                          </figure>
                        </div>
                        <div class="card-content">
                          <div class="media">
                            <div class="media-content">
                              <p class="title is-4">${marker.name}</p>
                            </div>
                          </div>
                          <div class="content">
                            ${marker.description}
                            <div class="companies-connected" style="padding: 20px 5px 0px";>${companies}</div>
                            <br>
                            <!--time datetime="2016-1-1">${marker.date_build}</time-->
                          </div>
                        </div>
                      </div>`);
      },
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
        this.zoom = 8
      }
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(() => {
        this.center = {
          lat: -34.60085926302077,
          lng:  -58.27508760231044
        };
      });
    }
  }
};
</script>


<style lang="scss" scoped>

.productores-section {
    width: 100%;
    margin: 0px 0%;
    padding: 30px 0 0; 
    overflow: hidden;

    .productores-info {
        width: 70%;
        margin: 0 auto 30px;
        text-align: center;
        h2 {
            font-size: 1.8rem;
        }
        strong {
            font-family: Montserrat;    
            font-weight: 700;
        }
        p {
            font-family: Montserrat;
            font-size: 1.1rem;
            color: #FFFFFF;
            line-height: 2;
            color: #13254A;
            letter-spacing: -0.15px;
        }
    }
}

</style>
