import ProCloudServices from "@/services/ProCloudServices.js";

export const namespaced = true;

export const state = {
    quotes: [],
    localidades:[]
};
export const mutations = {
  GET_LOCALIDADES(state, localidades) {
    state.localidades = localidades;
  },
  GET_QUOTES(state, quotes) {
    state.quotes = quotes;
  }
};
export const actions = {
    fetchLocalidades({ commit, dispatch}, provinciaId) {
        console.log(provinciaId);
        return ProCloudServices.getLocalidades(provinciaId)
        .then((response) => {
            
            //commit("SET_TOTAL_SCULPTURES", response.headers["x-total-count"]);
            commit("GET_LOCALIDADES", response.data);
        })
        .catch((error) => {
            const notification = {
            type: "error",
            message: "Tuvimos un problema al traer eventos: " + error.message,
            };
            dispatch("notification/add", notification, { root: true });
        });
    },
    fetchQuotes({ commit, dispatch}, data) {
        console.log(data);
        return ProCloudServices.getQuotes(data)
        .then((response) => {            
            //commit("SET_TOTAL_SCULPTURES", response.headers["x-total-count"]);
            commit("GET_QUOTES", response.data);
        })
        .catch((error) => {
            const notification = {
            type: "error",
            message: "Tuvimos un problema al traer eventos: " + error.message,
            };
            dispatch("notification/add", notification, { root: true });
        });
    }
};
export const getters = {
  /*getSculptureById: (state) => (id) => {
    return state.sculptures.find((sculpture) => sculpture.id === id);
  },*/
};
