import InfoAutoServices from "@/services/InfoAutoServices.js";

export const namespaced = true;

export const state = {
    brands: [],
    models:[],
    versions:[]
};
export const mutations = {
  GET_BRANDS(state, brands) {
    state.brands = brands;
  },
  GET_MODELS(state, models) {
    state.models = models;
  },
  GET_VERSIONS(state, versions) {
    state.versions = versions;
  }
};
export const actions = {
  fetchBrands({ commit, dispatch}) {
    return InfoAutoServices.getBrands()
      .then((response) => {
          
        //commit("SET_TOTAL_SCULPTURES", response.headers["x-total-count"]);
        commit("GET_BRANDS", response.data);
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: "Tuvimos un problema al traer eventos: " + error.message,
        };
        dispatch("notification/add", notification, { root: true });
      });
  },
  fetchModels({ commit, dispatch}, brandId) {
      console.log(brandId);
    return InfoAutoServices.getModels(brandId)
      .then((response) => {
          
        //commit("SET_TOTAL_SCULPTURES", response.headers["x-total-count"]);
        commit("GET_MODELS", response.data);
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: "Tuvimos un problema al traer eventos: " + error.message,
        };
        dispatch("notification/add", notification, { root: true });
      });
  },
  fetchVersions({ commit, dispatch}, {brandId, modelId}) {
      console.log(brandId);
    return InfoAutoServices.getVersions(brandId, modelId)
      .then((response) => {
          
        //commit("SET_TOTAL_SCULPTURES", response.headers["x-total-count"]);
        commit("GET_VERSIONS", response.data);
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: "Tuvimos un problema al traer eventos: " + error.message,
        };
        dispatch("notification/add", notification, { root: true });
      });
  }
};
export const getters = {
  /*getSculptureById: (state) => (id) => {
    return state.sculptures.find((sculpture) => sculpture.id === id);
  },*/
};
