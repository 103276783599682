import axios from "axios";
//import Nprogress from 'nprogress'

const apiClient = axios.create({
  baseURL: "https://api.procloud.com.ar/v/2.0.0/paseguros",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  timeout: 1000000
});

/*apiClient.interceptors.request.use(config => {
  Nprogress.start()
  return config
})

apiClient.interceptors.response.use(response => {
  Nprogress.done()
  return response
})*/

export default {
  getProductores() {
    return apiClient.get("/producers?orderby=razon_social,ASC&apikey=e4df42a9c36b3b185d442c3779200d7d");
  }
};
