import axios from "axios";
//import Nprogress from 'nprogress'

const apiClient = axios.create({
  baseURL: "https://cdn-multicotizadores.procloud.com.ar",
  //baseURL: "http://procloud-cdn-multicotizador.ba",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  timeout: 1000000
});

/*apiClient.interceptors.request.use(config => {
  Nprogress.start()
  return config
})

apiClient.interceptors.response.use(response => {
  Nprogress.done()
  return response
})*/

export default {
  getLocalidades(provinciaId) {
    return apiClient.get("/js/static/localidades/" + provinciaId + ".json");
  },
  getQuotes(data) {
    return apiClient.post("/api/multicotizar/auto", data);
  }
};
