<template>
  <div class="home-section sobre-paseguros-section" id="que-es">
    <div class="sobre-paseguros-image">
        <img src="../assets/sobre-paseguros.png">
    </div>
    <div class="sobre-paseguros-info">
        <h2>Sobre PASeguros</h2>
        <p>       
        <strong>PASeguros.com.ar</strong>, o <strong>"Productores Asesores de Seguros"</strong> es el sitio donde todos los productores de seguros de Argentina, pueden ofrecer a sus clientes, productos innovadores de forma digital.<br><br>
        Es la marca en la que un gran número de productores se apoya, para poder ofrecerte esos seguros que estás buscando, en ese momento exacto en el que los necesitás.<br><br>
        En <strong>PASeguros</strong>, trabajamos solo con productores de seguros matriculados.<br>
        <strong>Si hoy no tenés uno, no te preocupes!</strong><br>
        Cuando contrates cualquiera de nuestros productos, te podrás elegir uno cercano a tu ubicación!
        </p>
    </div>
    <div class="pointers">
        <img src="../assets/pointers.svg" alt="">
    </div>
    
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.sobre-paseguros-section {
    width: 100%;
    margin: 0 0%;
    padding: 40px 5%;
    overflow: hidden;
    background: #EDFDF7;
    box-sizing: border-box;
    position:relative;
    .pointers {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 100px;
    }
    .sobre-paseguros-info {
        float: right;
        width: 50%;
        text-align: left;
        padding-right: 5%;
        box-sizing: border-box;

        strong {
            font-family: Montserrat;    
            font-weight: 700;
        }
        p {
            font-family: Montserrat;
            font-size: 1.1rem;
            color: #FFFFFF;
            line-height: 2;
            color: #13254A;
            letter-spacing: -0.15px;
        }
    }
    .sobre-paseguros-image {
        float: left;
        width: 50%;
        margin-top: 50px;
        img {
            width: 70%;
            display: block;
        }
    }

     @media all and (max-width: 480px){
        .sobre-paseguros-info {
            float: none;
            width: 70%;
            text-align: center;
            padding-right: 0%;
            margin: 0 auto;

            
        }
        .sobre-paseguros-image {
            float: none;
            width: 100%;
            margin-top: 50px;
            margin-bottom: 50px;
            img {
                width: 70%;
                display: block;
                margin: 0 auto;
            }
        }
    }
}
</style>
