<template>
  <div class="home-section intro-section">
    <div class="intro-info">
        <h1>Hola! Bienvenido a<br><strong>PASeguros</strong></h1>
        <p>
            Atención! No estás en un sitio más de Seguros!<br><br>
            PASeguros es el padre de todos los seguros!<br>
            Porque en este sitio vas a encontrar todas las<br>
            compañías y todos los Productores!
        </p>
    </div>
    <div class="intro-image">
        <img src="../assets/ilustracion.png">
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.intro-section   {
    min-height: 600px;
    background-image: linear-gradient(-46deg, #4528DC 0%, #00E190 100%, #000000 100%);
    background: url('../assets/bg.jpg') top center no-repeat;
    background-position: center -250px ;
    background-size: 100% auto;
    .intro-info {
        /*position: absolute;
        left: 5%;
        top: 100px;
        text-align: left;
        max-width: 550px;*/
        text-align: left;
        float: left;
        width: 45%;
        margin-left: 5%;
        margin-top: 100px;
        h1 {
            font-family: Montserrat;
            font-size: 2.6rem;
            color: #FFFFFF;
            font-weight: 300;
            letter-spacing: 0.96px;
        }
        strong {
            font-family: Montserrat;    
            font-weight: 700;
        }
        p {
            font-family: Montserrat;
            font-size: 1.4rem;
            color: #FFFFFF;
            line-height: 1.7;
        }
        @media all and (max-width: 1280px) {
            h1 {
                font-size: 2rem;                
            }
            p {
                font-size: 1.1rem;
                line-height: 1.4;
            }
        }
    }

    .intro-image {
        /*position: absolute;
        right: 0;
        width: 50%;
        top: 100px;*/
        float: right;
        width: 45%;
        margin-left: 5%;
        margin-top: 100px;
        img {
            width: 100%;
        }
    }

    @media all and (max-width: 768px){
        min-height: 420px;
        background-position: center -50px ;    
        .intro-info {
            h1 {
                font-size: 1.6rem;
            }
            p {
                font-size: 0.8rem;
                line-height: 1.2rem;
            }
        }
        .intro-image {
            width: 39%;
        }
    }
    @media all and (max-width: 480px){
        min-height: 420px;
        background-position: center 0px ;    
        background-size: 170% auto;
        .intro-info {
            width: 100%;
            margin-top: 80px;
            h1 {
                font-size: 1.6rem;
            }
            p {
                font-size: 0.8rem;
                line-height: 1.2rem;
            }
        }
        .intro-image {
            width: 80%;
            margin-top: 0px;
        }
    }
}

</style>
