<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view :key="$route.fullPath" />
    </transition>
  </div>
</template>

<script>
export default {
  
};
</script>

<style lang='scss'>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap");

body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
}
#app {
  font-family: Montserrat, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.logo {
  float: left;
  width: 30%;
}

header {
  position: absolute;
  top: 0;
  left: 5%;
  width: 90%;
  .logo {
    text-align: left;
    margin-top: 15px;
  }
}

#nav {
  width: 70%;
  float: right;
  text-align: right;
  margin-top: 15px;
}

.gradient-bg-red {
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(213, 52, 77, 1) 33%
  );
}

/* TRANSITIONS */

.fade-enter {
  opacity: 0;
  transform: translateY(20px);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.8s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

button,
label,
input,
optgroup,
select,
textarea {
  display: inline-flex;
  font-family: "Open sans", sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  margin-bottom: 15px;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 2px solid #39b982;
}
label {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
}
input,
textarea {
  box-sizing: border-box;
  border: solid 1px rgba(0, 0, 0, 0.4);
}
textarea {
  width: 100%;
  overflow: auto;
  font-size: 20px;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.clear {
  clear: both;
}

textarea,
[type="text"],
[type="number"],
[type="search"],
[type="password"] {
  height: 40px;
  width: 100%;
  padding: 10px 10px;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  text-align: center;
    font-size: 0.8rem;
}
select{
  padding: 10px 10px;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;  
    font-size: 0.8rem;
}
textarea {
  min-height: 80px;
}
textarea::placeholder,
[type="text"]::placeholder {
  color: #333;
}
[type="text"]:focus,
[type="number"]:focus,
[type="search"]:focus,
[type="password"]:focus {
  border-color: #39b982;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
[hidden] {
  display: none;
}
</style>
